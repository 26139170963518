import React, { useMemo } from 'react';
import {
  CssBaseline,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider as StyledComponentProvider } from 'styled-components';
import { Provider as URQLProvider } from 'urql';

import 'shared/helpers/windowFunctions.ts';

import '../../public/fonts.css';

import { i18nextInit } from 'i18n';
import Bugsnag from 'lib/bugsnag';
import { createURQLClient } from 'lib/graphql/client';
import Error from 'pages/_error';
import CookieContextProvider from 'shared/components/CookieContextProvider.tsx';
import { consentModeDefaultsScript } from 'shared/googleTagManager';
import {
  getAlternateTags,
  getCanonicalTagHref,
  setRobotsDirective,
} from 'shared/helpers/seo';
import { useExperimentFromQueryParam } from 'shared/hooks/useExperimentFromQueryParam';
import { useServerEndpoint } from 'shared/hooks/useServerEndpoint';
import { segmentPreloadScript } from 'shared/segment';
import { GlobalStyles } from 'shared/styles/GlobalStyles';
import theme from 'system/theme';

const ErrorBoundary = Bugsnag.getPlugin('react');

function Marketing({ Component, pageProps }) {
  const router = useRouter();
  const serverEndpoint = useServerEndpoint();

  const gqlClient = useMemo(() => {
    return createURQLClient(serverEndpoint);
  }, [serverEndpoint]);

  const i18next = i18nextInit(router);
  const alternateTags = getAlternateTags({ pageProps, router });
  const canonicalHref = getCanonicalTagHref({ pageProps, router });

  useExperimentFromQueryParam();

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <React.Fragment>
        <URQLProvider value={gqlClient}>
          <Script
            id="google-analytics"
            dangerouslySetInnerHTML={{
              __html: consentModeDefaultsScript(),
            }}
          />
          <Script
            id="segment-preload-script"
            dangerouslySetInnerHTML={{
              __html: segmentPreloadScript(),
            }}
          />
          <Script
            id="segment-preload-script"
            dangerouslySetInnerHTML={{
              __html: segmentPreloadScript(),
            }}
          />
          <Head>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width"
            />
            <link
              rel="preload"
              href="/fonts/subsetted/Poppins-Regular-subset.woff2"
              as="font"
              type="font/woff2"
              crossOrigin=""
            />
            <link
              rel="preload"
              href="/fonts/subsetted/Poppins-SemiBold-subset.woff2"
              as="font"
              type="font/woff2"
              crossOrigin=""
            />
            <link
              rel="preload"
              href="/fonts/subsetted/Poppins-Medium-subset.woff2"
              as="font"
              type="font/woff2"
              crossOrigin=""
            />
            <link
              rel="preload"
              href="/fonts/subsetted/Poppins-Bold-subset.woff2"
              as="font"
              type="font/woff2"
              crossOrigin=""
            />
            <meta
              name="robots"
              content={setRobotsDirective(pageProps, router.locale)}
            />

            {alternateTags.map(({ href, hrefLang }) => (
              <link
                key={hrefLang}
                rel="alternate"
                hrefLang={hrefLang}
                href={href}
              />
            ))}

            {canonicalHref && <link rel="canonical" href={canonicalHref} />}

            <link rel="icon" href="/favicon.ico" />
            <title>Catering anfragen & Angebote erhalten | heycater!</title>
            <meta name="theme-color" content={theme.palette.primary.main} />
          </Head>
          <I18nextProvider i18n={i18next}>
            <StyledComponentProvider theme={theme}>
              <MuiThemeProvider theme={theme}>
                <StylesProvider injectFirst>
                  <CssBaseline />
                  <GlobalStyles />
                  <CookieContextProvider>
                    <Component {...pageProps} />
                  </CookieContextProvider>
                </StylesProvider>
              </MuiThemeProvider>
            </StyledComponentProvider>
          </I18nextProvider>
        </URQLProvider>
      </React.Fragment>
    </ErrorBoundary>
  );
}

export default Marketing;

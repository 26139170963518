export const CUSTOM_ABLYFT_EVENT = {
  formSubmission: 'funnel-form-submission-custom-tracking',
  formWithBestsellerSubmission: 'bestseller-submission',
  interactedWithEmbeddedFunnelOnHomepageNotLoggedInUnique:
    'interacted-with-embedded-funnel-on-homepage-not-logged-in-unique',
  interactedWithEmbeddedFunnelOnHomepageNotLoggedInEvery:
    'interacted-with-embedded-funnel-on-homepage-not-logged-in-every',
  qfEngagement: 'qf-engagement-first-action-or-cta',
  triggeredFunnelEntrypointOnHomepageNotLoggedInUnique:
    'triggered-funnel-entry-point-on-homepage-not-logged-in-unique',
  triggeredFunnelEntrypointOnHomepageNotLoggedInEvery:
    'triggered-funnel-entry-point-on-homepage-not-logged-in-every',
  calendlyEngagement: 'calendly-engagement',
} as const;

type CustomEvent = typeof CUSTOM_ABLYFT_EVENT[keyof typeof CUSTOM_ABLYFT_EVENT];

const COOKIE_KEY = 'ablyft_exps';
const SESSION_STORAGE_PREVIEW_KEY = 'ablyft_preview_combs';
const experimentEnv =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? 'production' : 'test';

const EXPERIMENT_IDS = {
  production: {
    customerTierABC: {
      id: 56174789,
      original: 52709002,
      variationB: 23832192,
      variationC: 31201749,
    },
  },
  test: {
    customerTierABC: {
      id: 60095517,
      original: 57095943,
      variationB: 73726581,
      variationC: 64000750,
    },
  },
};

export const EXPERIMENT = {
  homepageRedesign: {
    id: 48515366,
    variations: {
      original: defineVariation(0),
      variation: defineVariation(24224136, { queryParam: 'GROW_285' }),
    },
  },
  customerTierABC: {
    id: EXPERIMENT_IDS[experimentEnv].customerTierABC.id,
    variations: {
      original: defineVariation(
        EXPERIMENT_IDS[experimentEnv].customerTierABC.original
      ),
      variationB: defineVariation(
        EXPERIMENT_IDS[experimentEnv].customerTierABC.variationB
      ),
      variationC: defineVariation(
        EXPERIMENT_IDS[experimentEnv].customerTierABC.variationC
      ),
    },
  },
} as const;

export type Variation = { id: number; queryParam?: string };
function defineVariation(
  id: number,
  { queryParam }: { queryParam?: string } = {}
): Variation {
  return { id, queryParam };
}

export const AB_VARIATIONS = Object.values(EXPERIMENT).map((i) => i.variations);

type ABExperiment = typeof EXPERIMENT;

export type ExperimentName = keyof typeof EXPERIMENT;
export type ABLyftVariation = typeof EXPERIMENT[keyof typeof EXPERIMENT]['variations'];
export type VariationName<
  T extends ExperimentName
> = keyof ABExperiment[T]['variations'];

export const ABLyft = {
  trackCustomEvent(eventName: CustomEvent) {
    if (typeof window !== 'undefined') {
      window.ablyft?.push({
        eventType: 'custom',
        eventName,
        eventValue: 0,
      });
    }
  },
};

export function enforceExperimentForTesting(
  variation: Variation,
  callback?: () => void
) {
  if (typeof window === 'undefined') {
    console.warn(
      'Enforcing experiment failed. It can only be used on the client side'
    );
    console.log('enforceExperimentForTesting:', variation);
    return;
  }

  const lookup = getExperimentOfVariation(variation);

  if (!lookup) {
    console.log('enforceExperimentForTesting: lookup not found', variation);
    return;
  }

  const { name: experimentName, variationName } = lookup;

  alert(
    `The page will reload, and you'll be bucketed into the experiment: [${experimentName}] and variation: [${variationName}] `
  );
  console.log('enforceExperimentForTesting:', variation, lookup);
  sessionStorage.setItem(
    '__enforced_experiment',
    `${experimentName}:${variationName}`
  );

  if (callback) {
    callback();
  } else {
    const url = new URL(window.location.href);
    window.location.href = url.toString();
  }
}

/**
 *
 * To manually enable an experiment, run the following
 * ```js
 * (function(){
 *  const experimentName = '<experiment name>'
 *  const variationName= '<variation name>'
 *  sessionStorage.setItem('__enforced_experiment', `${experimentName}:${variationName}`)
 *  window.location = window.location
 * })()
 * ```
 */
export function isVariationActive(variation: Variation) {
  const lookup = getExperimentOfVariation(variation);

  if (!lookup) {
    return false;
  }
  const {
    experiment: foundExperiment,
    name: experimentName,
    variationName,
  } = lookup;

  const experimentId = foundExperiment.id;
  const variationId = variation.id;
  if (!variationId) {
    return false;
  }

  if (typeof window === 'undefined') {
    return false;
  }

  const checkIfManuallyEnabled =
    !process.env.NEXT_PUBLIC_VERCEL_ENV ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';

  if (checkIfManuallyEnabled) {
    const enforcedExperiment = sessionStorage.getItem('__enforced_experiment');
    if (enforcedExperiment === `${experimentName}:${variationName}`) {
      return true;
    }
  }

  const experiment = window?.ablyft
    ?.get('experiments')
    .find(({ id }) => id === experimentId);

  if (!experiment) {
    return false;
  }

  const isPreview = experiment.status === 'preview';
  const isRunning = experiment.status === 'running';

  // If it's a preview, ABLYft stores that data in the sessionStorage
  if (isPreview) {
    const previewValues = sessionStorage.getItem(SESSION_STORAGE_PREVIEW_KEY);
    if (!previewValues) {
      return false;
    }
    const parsedPreviews = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_PREVIEW_KEY) || ''
    );
    if (Array.isArray(parsedPreviews)) {
      const isPreviewActive = !!parsedPreviews.find((item) => {
        if (typeof item === 'string') {
          const [itemExperimentId, itemVariantId] = item.split('_');
          if (
            Number(itemExperimentId) == experimentId &&
            Number(itemVariantId) == variationId
          ) {
            return true;
          }
        }
        return false;
      });
      return isPreviewActive;
    }
  }

  // If it's active, ABLYft stores that data as a cookie
  if (isRunning) {
    const tools = window?.ablyft?.getTools();
    if (!tools) {
      return false;
    }
    const cookieValue = tools.getCookie(COOKIE_KEY);
    if (!cookieValue) {
      return false;
    }

    const parsedCookie = JSON.parse(cookieValue);
    if (typeof parsedCookie === 'object') {
      return parsedCookie[experimentId] === variationId;
    }
  }
  return false;
}

export function getExperimentOfVariation(variation: Variation) {
  const lookup = Object.entries(EXPERIMENT).find(([_, experiment]) =>
    Object.values(experiment.variations).includes(variation)
  );

  if (!lookup) {
    return null;
  }

  const [experimentName, foundExperiment] = lookup;
  const foundVariation = Object.entries(foundExperiment.variations).find(
    ([_, _variation]) => _variation === variation
  );

  if (!foundVariation) {
    return null;
  }
  const variationName = foundVariation[0];

  return {
    name: experimentName as ExperimentName,
    experiment: foundExperiment,
    variationName: variationName,
  };
}
